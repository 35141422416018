<template>
    <el-dialog title="导入档案" :close-on-click-modal="false" append-to-body :visible.sync="visible">
        <div v-loading="importLoading" element-loading-text="数据导入中">
            <div v-if="!importLoading">
                <el-result icon="success" title="导入成功" :subTitle="'本次共导入'+importData.number+'条数据。'"
                           v-if="importData.code"></el-result>
                <el-result icon="error" title="导入失败" v-else></el-result>
                <div v-if="!importLoading && !importData.code" class="text_center">
                    <div v-for="(item, index) in importData.list" :key="index">{{item}}</div>
                </div>
            </div>
        </div>
        <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="visible = false">关闭</el-button>
            </span>
    </el-dialog>
</template>

<script>
    export default {
        data() {
            return {
                visible: false,
                importLoading: true,
                importData: {},
            }
        },
        methods: {
            init(param, archivesBasicDataId, archivesDataSource) {
                this.importLoading = true
                this.importData = {}
                this.visible = true
                const formData = new FormData()
                formData.append('archivesBasicDataId', archivesBasicDataId)
                formData.append('archivesDataSource', archivesDataSource)
                formData.append('file', param.file)
                this.$axios(this.api.record.excelImport, formData, 'post').then(data => {
                    if (data.status) {
                        this.importData = data.data
                        if (data.data.code) {
                            this.$emit("refreshDataList")
                        }
                    } else {
                        this.$message.error(data.msg)
                    }
                    this.importLoading = false
                })
            }
        },
    }
</script>

<style scoped>

</style>
